import { BaseApi } from '@/api/base-api'
import { RESOURCE_JOBS_EXPECTED_COST_LINES } from '@/shared/constants/resources'
import Vue from 'vue'

class JobExpectedCostLinesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  createOrderByJobExpectedCostLine(jobExpectedCostLineId, provider) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${this.resource}/create-order/${jobExpectedCostLineId}`, { provider })
        Vue.$toast('Recurso creado con éxito.')
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al crear el recurso. Por favor, inténtelo de nuevo mas tarde')
        reject(error)
      }
    })
  }

  cloneJobExpectedCostLine(jobExpectedCostLineId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`/${this.resource}/clone/${jobExpectedCostLineId}`)
        Vue.$toast('Recurso clonado con éxito.')
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al clonar el recurso. Por favor, inténtelo de nuevo mas tarde')
        reject(error)
      }
    })
  }
}

export default new JobExpectedCostLinesApi(RESOURCE_JOBS_EXPECTED_COST_LINES)
